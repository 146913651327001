import { useEffect } from "react";
import about from "../assets/about.jpg";
import logo from "../assets/logo.png";
// import ProductCard from "../components/ProductCard";
// import { products } from "../db//db.js";
import santro from "../assets/santro.mp4";
import santro1 from "../assets/santro1.mp4";
import santro2 from "../assets/santro2.mp4";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  return (
    <>
      <section className="w-[100vw] overflow-hidden " data-aos="fade-right">
        <div className="relative p-0  flex items-center align-middle justify-center bg-[#f0b1ba]">
          <img src={about} alt="img" className="w-auto min-h-[100vh] " />
          <div className="absolute right-0 left-1/2 w-[1/2] overflow-hidden pr-5">
            <h1 className="text-5xl pb-2 font-bold text-[32px] sm:text-2xl lg:text-6xl">
              About us
            </h1>
            <p className="overflow-hidden text-[10px] sm:text-md text-ellipsis lg:text-lg text-white">
              Santro was born in 1997 from the shared dream of two visionaries,
              Sivakumar and Easwaran, who both hailed from agricultural
              backgrounds. Their goal was simple yet ambitious—to establish a
              brand that catered to the modern fashion needs of people while
              staying true to their roots. With a passion for quality and
              innovation, they began by manufacturing innerwear with a team of
              just five, including themselves.
            </p>
          </div>
        </div>
      </section>
      <section
        className=" pt-10 pb-10 flex flex-row flex-wrap lg:flex-nowrap xl:flex-nowrap  gap-5 pr-[5%] pl-[5%] justify-center"
        data-aos="fade-left"
      >
        <div className="flex justify-center">
          <div className="rounded-lg shadow-lg bg-white max-w-sm">
            <a href="#!">
              <video
                width="320"
                height="240"
                controls
                className="w-full rounded-t-lg"
              >
                <source src={santro} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </a>
            <div className="p-6">
              <div className="flex w-full justify-start mb-2">
                <img
                  src={logo}
                  alt="logo"
                  className="max-h-[50px] lg:max-h-[60px] md:max-h-[45px] flex align-middle justify-start"
                />
              </div>
              <p className="text-gray-700 text-base mb-4">
                What started as a humble venture has since grown into a
                powerhouse in the fashion industry. Today, Santro is a name
                synonymous with comfort, style, and reliability. The brand now
                provides employment to over 1,000 laborers, directly and
                indirectly, fostering a strong sense of community and
                empowerment. From its modest beginnings, Santro has expanded its
                footprint across more than 14 states in India, serving millions
                of satisfied customers.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="rounded-lg shadow-lg bg-white max-w-sm">
            <a href="#!">
              <video
                width="320"
                height="240"
                controls
                className="w-full rounded-t-lg"
              >
                <source src={santro1} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </a>
            <div className="p-6">
              <div className="flex w-full justify-start mb-2">
                <img
                  src={logo}
                  alt="logo"
                  className="max-h-[50px] lg:max-h-[60px] md:max-h-[45px] flex align-middle justify-start"
                />
              </div>
              <p className="text-gray-700 text-base mb-4">
                Despite their success, Sivakumar and Easwaran remain as
                dedicated as ever to their founding mission. They continue to
                work tirelessly to ensure that Santro not only meets but exceeds
                the expectations of its customers. Their relentless pursuit of
                excellence is now driving Santro toward the global stage, with
                ambitions to become an internationally recognized name in
                fashion.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="rounded-lg shadow-lg bg-white max-w-sm">
            <a href="#!">
              <video
                width="320"
                height="240"
                controls
                className="w-full rounded-t-lg"
              >
                <source src={santro2} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </a>
            <div className="p-6">
              <div className="flex w-full justify-start mb-2">
                <img
                  src={logo}
                  alt="logo"
                  className="max-h-[50px] lg:max-h-[60px] md:max-h-[45px] flex align-middle justify-start"
                />
              </div>
              <p className="text-gray-700 text-base mb-4">
                At Santro, we believe in delivering more than just products; we
                deliver a promise of quality, comfort, and timeless fashion. As
                we look to the future, we remain committed to innovation and
                sustainability, all while preserving the values that laid the
                foundation of our brand.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="pt-10 pb-10 p-5 lg:mt-10 max-w-[100vw]">
        <div className="flex align-middle justify-center items-center lg:mt-5 lg:mb-10">
          <h1 className=" text-2xl lg:text-6xl">Products</h1>
        </div>
        <div className="flex flex-row max-w-[100vw] p-10 flex-wrap align-middle justify-evenly ">
          <div className=" w-full lg:w-1/3 flex flex-col align-middle justify-center items-center">
            <h1 className="text-xl lg:text-2xl mb-5 lg:mb-10">
              Boy&apos;s Wardrobe
            </h1>
            <div className="flex flex-row flex-wrap gap-10 items-center justify-center align-middle">
              {products[0].boyswardrobe.map((e) => {
                return (
                  <div key={e.id}>
                    <ProductCard card={e} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" w-full lg:w-1/3 flex flex-col align-middle justify-center items-center">
            <h1 className="text-xl lg:text-2xl mb-5 lg:mb-10">
              Girl&apos;s Wardrobe
            </h1>
            <div className="flex flex-row flex-wrap gap-10 items-center justify-center align-middle">
              {products[0].girlswardrobe.map((e) => {
                return (
                  <div key={e.id}>
                    <ProductCard card={e} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default About;
