import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { categoryWiseProduct } from "../db/db.js";
const Productview = () => {
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  const { id } = useParams();
  const filered_product = categoryWiseProduct.filter((e) => {
    return Number(id) === e.category_id;
  });
  return (
    <div className="flex flex-row flex-wrap justify-center w-[100vw] pt-[10%] pb-[10%] pr-[5%] pl-[5%] gap-5  ">
      {filered_product.map((e) => {
        return (
          <div
            className="shadow-lg shadow-gray-700 border-1 border-black cursor-pointer min-h-[500px] "
            key={e.id}
            data-aos="fade-down"
          >
            <div className="relative flex flex-col  align-middle justify-center items-center p-2">
              <img
                src={e.product_img}
                alt="product_img"
                className="max-h-[500px]"
                loading="lazy"
              />
              {/* <h3 className="text-[14px] text-center m-2 text-black">
                {e.product_name}
              </h3> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Productview;
