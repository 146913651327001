import { useEffect, useState } from "react";
import loader from "../assets/Spinner.gif";
export const Spinner = () => {
  const [spin, setSpin] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setSpin(false);
    }, 2000);
  });
  return (
    <>
      {spin ? (
        <div className="  h-[100vh] w-[100vw] flex align-middle justify-center items-center ">
          <img src={loader} alt="spinner" className=" h-auto" />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
