import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Spinner } from "../components/Spinner";

const Layout = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <div className="overflow-x-hidden">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    </Suspense>
  );
};

export default Layout;
