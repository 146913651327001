export const category = [
  {
    id: 1,
    category_img: require("../assets/boys1.webp"),
    category_name: "Boy's",
    off: false,
    off_amount: 0,
  },
  {
    id: 2,
    category_img: require("../assets/girls_3.webp"),
    category_name: "Girl's",
    off: false,
    off_amount: 0,
  },
  {
    id: 3,
    category_img: require("../assets/newstyle.webp"),
    category_name: "Kids",
    off: true,
    off_amount: "New Style",
  },
];
export const categoryWiseProduct = [
  {
    id: 1,
    product_img: require("../assets/girls_1.webp"),
    product_name: "Boys",
    category_id: 2,
    off: false,
    off_amount: 0,
  },
  {
    id: 2,
    product_img: require("../assets/girls_2.webp"),
    product_name: "Girl",
    category_id: 2,
    off: false,
    off_amount: 0,
  },
  {
    id: 3,
    product_img: require("../assets/girls_3.webp"),
    product_name: "Girl",
    category_id: 2,
    off: false,
    off_amount: 0,
  },
  {
    id: 4,
    product_img: require("../assets/girls_4.webp"),
    product_name: "Girl",
    category_id: 2,
    off: false,
    off_amount: 0,
  },
  {
    id: 5,
    product_img: require("../assets/girls_5.webp"),
    product_name: "Girl",
    category_id: 2,
    off: false,
    off_amount: 0,
  },
  {
    id: 6,
    product_img: require("../assets/boy1.webp"),
    product_name: "Boys",
    category_id: 1,
    off: false,
    off_amount: 0,
  },
  {
    id: 7,
    product_img: require("../assets/boy2.webp"),
    product_name: "Girl",
    category_id: 1,
    off: false,
    off_amount: 0,
  },
  {
    id: 8,
    product_img: require("../assets/boy3.webp"),
    product_name: "Girl",
    category_id: 1,
    off: false,
    off_amount: 0,
  },
  {
    id: 9,
    product_img: require("../assets/boy4.webp"),
    product_name: "Girl",
    category_id: 1,
    off: false,
    off_amount: 0,
  },
  {
    id: 10,
    product_img: require("../assets/boy5.webp"),
    product_name: "Girl",
    category_id: 1,
    off: false,
    off_amount: 0,
  },
  {
    id: 11,
    product_img: require("../assets/newstyle6.webp"),
    product_name: "Girl",
    category_id: 3,
    off: false,
    off_amount: 0,
  },
  {
    id: 12,
    product_img: require("../assets/newstyle1.webp"),
    product_name: "Boys",
    category_id: 3,
    off: false,
    off_amount: 0,
  },
  {
    id: 13,
    product_img: require("../assets/newstyle2.webp"),
    product_name: "Girl",
    category_id: 3,
    off: false,
    off_amount: 0,
  },
  {
    id: 14,
    product_img: require("../assets/newstyle3.webp"),
    product_name: "Girl",
    category_id: 3,
    off: false,
    off_amount: 0,
  },
  {
    id: 15,
    product_img: require("../assets/newstyle4.webp"),
    product_name: "Girl",
    category_id: 3,
    off: false,
    off_amount: 0,
  },
];
export const products = [
  {
    boyswardrobe: [
      {
        id: 1,
        product_category_img: require("../assets/boyspary.jpg"),
        product_category_name: "Party",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 2,
        product_category_img: require("../assets/boyethnic.webp"),
        product_category_name: "Ethnic",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 3,
        product_category_img: require("../assets/boysinner.jpg"),
        product_category_name: "InnerWear",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 4,
        product_category_img: require("../assets/boysnightsuits.webp"),
        product_category_name: "Night Suits",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 5,
        product_category_img: require("../assets/boysshoe.webp"),
        product_category_name: "Shoe",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 6,
        product_category_img: require("../assets/boysaccessories.jpg"),
        product_category_name: "Accessories",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 7,
        product_category_img: require("../assets/boysshortstees.webp"),
        product_category_name: "Tees & Shirts",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 8,
        product_category_img: require("../assets/onesisboy.webp"),
        product_category_name: "Onesies",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 9,
        product_category_img: require("../assets/summerboy.webp"),
        product_category_name: "Summer Wear",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 10,
        product_category_img: require("../assets/fulllengthboys.webp"),
        product_category_name: "Full Length",
        product_off: false,
        product_off_amount: 0,
      },
    ],
    girlswardrobe: [
      {
        id: 1,
        product_category_img: require("../assets/party.png"),
        product_category_name: "Party",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 2,
        product_category_img: require("../assets/girldress.webp"),
        product_category_name: "Dress",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 3,
        product_category_img: require("../assets/summergirl.webp"),
        product_category_name: "Summer Wear",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 4,
        product_category_img: require("../assets/girlsethnic.webp"),
        product_category_name: "Ethnic",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 5,
        product_category_img: require("../assets/girlsinner.webp"),
        product_category_name: "Inner Wear",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 6,
        product_category_img: require("../assets/girlsshoe.webp"),
        product_category_name: "Shoe",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 7,
        product_category_img: require("../assets/girlsaccessories.jpg"),
        product_category_name: "Accessories",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 8,
        product_category_img: require("../assets/girlstopandtees.webp"),
        product_category_name: "Top & Tees",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 9,
        product_category_img: require("../assets/onesisgirls.webp"),
        product_category_name: "Onesies",
        product_off: false,
        product_off_amount: 0,
      },
      {
        id: 10,
        product_category_img: require("../assets/fulllengthgirl.webp"),
        product_category_name: "Full Length",
        product_off: false,
        product_off_amount: 0,
      },
    ],
  },
];
export const carousel = [
  {
    id: 1,
    carousel_img: require("../assets/bgsantro.webp"),
    carousel_logo: require("../assets/logowhite.png"),
    mobile_img: require("../assets/newstyle6.webp"),
    off: false,
    off_amount: 0,
  },
  {
    id: 2,
    carousel_img: require("../assets/bgsantro2.webp"),
    carousel_logo: require("../assets/logowhite.png"),
    mobile_img: require("../assets/newstyle1.webp"),
    off: false,
    off_amount: 0,
  },
];
