import { useEffect } from "react";
import Carousal from "../components/Carousal";
import CategoryCaed from "../components/CategoryCaed";
import { carousel, category } from "../db/db.js";

const Home = () => {
  // const res = fetch("../assets/db.json");
  // const data = res.json();
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);

  return (
    <main className="overflow-x-hidden w-[100vw] flex   flex-col">
      <section className="bg-white flext max-w-[100vw] ">
        <Carousal carousal={carousel} />
      </section>
      <section className="max-w-[100vw] mt-10">
        <div className="w-full bg-[#e30015] h-[10px]"></div>
        <div className="flex flex-row flex-wrap gap-10 items-center justify-center align-middle pt-10">
          {category.map((e) => {
            return (
              <div key={e.id}>
                <CategoryCaed card={e} />
              </div>
            );
          })}
        </div>
      </section>
      <section className="w-full flex flex-col align-middle justify-center mt-10 mb-10">
        <div className="w-full bg-[#e30015] h-[10px]"></div>
        <h1 className="text-white">Santro Outer & Inner Garments</h1>
        <p data-aos="zoom-in" className="pr-[10%] pl-[10%]">
          Beyond infancy to adolescence, childhood differs from certain
          boundaries in every culture, customary standards regarding
          children&apos;s abilities, limits, and appearance are evident
          throughout the whole growth cycle. Clothes has a significant impact on
          how children appear throughout history. Changes may be seen throughout
          the course of children&apos;s clothes.
        </p>
      </section>
    </main>
  );
};

export default Home;
