import { Link } from "react-router-dom";

const CategoryCaed = (prop) => {
  const image = prop.card.category_img;
  return (
    <Link to={"product/" + prop.card.id}>
      <div
        data-aos="flip-right"
        className="relative box flex flex-col  align-middle justify-center items-center mb-5 shadow-lg shadow-gray-700 border-1 border-black cursor-pointer"
      >
        {prop.card.off ? (
          <div className="ribbon">
            <h3 className="text-white text-center">{prop.card.off_amount}</h3>
          </div>
        ) : (
          ""
        )}
        <img src={image} alt="category" className="max-h-[450px]  " />
        <h3 className="  text-[#e30015] text-shadow p-5">
          {prop.card.category_name}
        </h3>
      </div>
    </Link>
  );
};

export default CategoryCaed;
